<template>
    <div>
        <MXPageLayout>
            <div slot="filter">
                <Row>
                    <Form style="display:flex;justify-content:end" ref="formInline" :model="fromData" inline :label-width="10" label-position="right">
                        <FormItem>
                            <Select v-model="fromData.elevatorCode" placeholder="选择电梯" clearable style="width:180px">
                                <Option v-for="(item, index) in elevatorSelectList" :key="index" :value="item.v_elevator_code">
                                    {{ item.v_elevator_name }}</Option>
                            </Select>
                        </FormItem>
                        <FormItem>
                            <DatePicker @on-change="fromData.selectStartTime = $event" :value="fromData.selectStartTime" format="yyyy-MM-dd" type="date" placeholder="开始时间">
                            </DatePicker>
                        </FormItem>
                        <Button type="primary" shape="circle" icon="ios-search" @click="searchList()">搜索
                        </Button>
                    </Form>
                </Row>
            </div>
            <div slot="title">
                {{pageTitle}}
            </div>
            <div slot="content">
                <div class="echart-box" id="E_zkcl" style="width:100%;height:600px;"></div>
            </div>
        </MXPageLayout>
    </div>
</template>
<script>
import { Message } from 'view-design'
import { pedestrianFlowStatistics } from '@/api/ststistics/index.js'
import { searchElevatorNotPage } from '@/api/elevator/index.js'
import * as echarts from 'echarts'
import chinaCities from 'echarts/map/json/china-cities.json'
import chongqing from "@/assets/chongqing.json";
import "echarts-gl";
import Statistics from '@/pages/Statistics/statistics'

export default {
    data() {
        return {
            DisplayTypeDataList: [{
                    value: 'asc',
                    label: '升序'
                },
                {
                    value: 'desc',
                    label: '降序'
                }
            ],
            model1: '',
            fromData: {
                elevatorCode: '',
                selectStartTime: this.formatDateToStartOfDay(this.formatDate(-1)),
                selectEndTime: this.formatDateToStartOfDay(this.formatDate(0)),
            },
            elevatorSelectList: [],
            zoom: 1,
            villageFormItemSelectList: [],

        }
    },
    computed: {
        theme() {
            return this.$store.getters.theme
        },
        user() {
            return this.$store.state.user
        },
    },
    mixins: [Statistics],
    mounted() {
        this.reqElevatorList()
    },
    watch: {
        'fromData.elevatorCode'(newVal, oldVal) {},
        'theme'(newVal, oldVal) {
            if (newVal != oldVal) {
                this.searchList()
            }
        }
    },
    methods: {
        searchList() {
            let params = JSON.parse(JSON.stringify(this.fromData))
            params.selectStartTime = this.formatDateToStartOfDay(params.selectStartTime)
            let startTime = params.selectStartTime + ""
            params.selectEndTime = this.formatDateToEndOfDay(startTime.slice(0, 10))
            pedestrianFlowStatistics(params).then(res => {
                let xArr = res.info.xaxis.data
                let xArrTemp = []
                for (let i = 0; i < xArr.length; i++) {
                    let dateStr = xArr[i]
                    xArrTemp.push(dateStr.slice(11, 16))
                }
                let data = res.info
                data.xaxis.data = xArrTemp
                let myChart = this.$echarts.init(document.getElementById('E_zkcl'), 'dark')
				this.chart = myChart

                let obj = {
                    'type': 'total',
                    'data': []
                }

                for(let idx = 0; idx < data.series.restList[0].data.length; idx++) {
                    obj['data'][idx] = data.series.restList[0].data[idx] + data.series.restList[1].data[idx]
                }

                data.series.restList.push(obj)

                let legendData = data.series.restList.map((item) => {
                    if (item.type == 'up') return '上行'
                    else if(item.type == 'down') return '下行'
                    else
                        return '吞吐量'
                })
                let seriesData = data.series.restList.map((item) => {
                    return {
                        name: item.type == 'up' ? '上行' : item.type == 'down' ? '下行' : '吞吐量',
                        type: 'line',
                        // stack: 'Total',
                        data: item.data,
						itemStyle: {
						    normal: {
						        color: item.type == 'up' ? '#5a71c0' : item.type == 'down' ? '#9eca7f' : 'yellow', //改变折线点的颜色
						        lineStyle: {
						            color: item.type == 'up' ? '#5a71c0' : item.type == 'down' ? '#9eca7f' : 'yellow' //改变折线颜色
						        },
								label:{show:true,color:'#fff'}
						    }
						}
                    }
                })

                let option = {
                    backgroundColor: '',
                    title: {
                        text: ''
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: legendData,
                        // itemStyle: {
                        //     color: '#fff'
                        // },
                        textStyle: {
                            color: '#fff',
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    // toolbox: {
                    //     feature: {
                    //         saveAsImage: {}
                    //     }
                    // },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: data.xaxis.data,
                        axisLabel: {
                            formatter: '{value}',
                            textStyle: {
                                fontSize: 12,
                                color: '#fff'
                            },
                            rotate: 36,

                        },

                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}',
                            textStyle: {
                                fontSize: 16,
                                color: '#fff'
                            }
                        }
                    },
                    series: seriesData
                };
                myChart.setOption(option)
            })
        },
        reqElevatorList() {
            searchElevatorNotPage(this.buildParams({})).then(res => {
                this.elevatorSelectList = res.info
                if (this.elevatorSelectList.length)
                    this.fromData.elevatorCode = this.elevatorSelectList[0].v_elevator_code
                this.searchList()
            })
        },
        updatePageTitle() {
            if (this.fromData.elevatorCode && this.fromData.elevatorCode.length) {
                let elevator = this.elevatorSelectList.find((elevator) => {
                    return elevator.v_elevator_code == this.fromData.elevatorCode
                })
                if (elevator) {
                    this.pageTitle = `${elevator.v_elevator_name}${this.getModuleName()}`
                }
            } else {
                this.pageTitle = ''
            }
        },
        getModuleName() {
            return '单梯人流量'
        },
        buildParams(params) {
            // params.vProjectId = this.user.user.v_project_id
            return params
        },
        onChangeEndTime(e) {
            // this.fromData.endTime = e + ' 23:59:59'
        },
    }
}
</script>
<style scoped>
::v-deep .ivu-select-selected-value {
    color: #fff !important;
}
</style>